var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RGB_NUM_ELEMENTS = 3;
exports.RGBA_NUM_ELEMENTS = 4;
exports.XYZ_NUM_ELEMENTS = 3;
exports.UV_NUM_ELEMENTS = 2;
exports.INDEX_NUM_ELEMENTS = 1;
exports.SCATTER_PLOT_CUBE_LENGTH = 2;
export default exports;