import _scatter_gl from "./scatter_gl";
import _data from "./data";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var scatter_gl_1 = _scatter_gl;
exports.ScatterGL = scatter_gl_1.ScatterGL;
var data_1 = _data;
exports.Dataset = data_1.Dataset;
export default exports;
export const __esModule = exports.__esModule,
      ScatterGL = exports.ScatterGL,
      Dataset = exports.Dataset;